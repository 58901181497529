import React, { useState } from 'react'
import Layout from 'src/layouts/BaseLayout'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import Home from '@interco/icons/build-v4/orangeds/MD/home'
import { Link } from 'gatsby'
import Icon from 'src/components/UI/MarkdownIcon'
import InvestmentsTab from 'src/components/InvestmentsTab'
import FAQ from 'src/components/StructuredData/FAQ'
import BlogArticles from 'src/components/BlogArticles'
import Img from 'gatsby-image'
import pageContext from './pageContext.json'
import usePageQuery from './pageQuery'
import { FAQSection, Section, Box } from './style'
// import OpenModal from 'src/components/OpenModal'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { FixedIncome } from 'src/components/InvestmentSimulators'
import DisclaimerInterInvest from 'src/components/DisclaimerInterInvest'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import headerJson from './breadcrumb.json'
import { Modal } from 'src/components/Modal'
import useDomReady from 'src/hooks/window/useDomReady'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'
import QR_CODE from '../assets/images/qrcode-poupanca.png'
import QrCodeKids from '../assets/images/qrcode-conta-kids-poupanca.png'

function Poupanca () {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()
  const domReady = useDomReady()
  const [ isModalOpen, setIsModalOpen ] = useState(false)

  const dataLayer: IDataLayerParams = {
    section: 'dobra_07',
    section_name: 'Disclaimer',
    element_action: 'click button',
    element_name: '',
  }

  const handleDatalayerEvent = () => {
    sendDatalayerEvent({
      section: 'dobra_2',
      element_action: 'click button',
      element_name: 'Comece a investir agora',
      section_name: 'Conheça nossos produtos',
    })
  }

  const openAccountRightForm = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <OpenAccountRightForm
        closeModal={() => setIsModalOpen(false)}
        formName='Formulario abertura de contas'
        dataLayer={dataLayer}
        qrBaixeApp={QR_CODE}
        asFilledLnk='https://inter-co.onelink.me/Qyu7/ez1pd6k5'
        qrCodeContaKids={QrCodeKids}
      />
    </Modal>
  )

  return (
    <Layout pageContext={pageContext}>
      {openAccountRightForm}
      <Section>
        <div className='container py-5'>
          <div className='row align-items-md-center'>
            <div className='col-12 col-md-6 col-lg-5 col-xl-6 offset-lg-1 offset-xl-0 order-md-last'>
              <Img fluid={data.bannerPoupanca.fluid} alt='Porquinho da Inter Invest um do lado do outro' />
            </div>
            <div className='col-12 col-md-6 col-lg-6 col-xl-6 pr-md-0'>
              <div className='d-md-block text-grayscale--500 mb-lg-2'>
                <div className='bread mb-2'>
                  <Link
                    to='/'
                    className='d-md-inline'
                  ><Home height={24} width={24} color='#6A6E81' />
                  </Link>
                  <OrangeIcon className='d-md-inline mx-2' icon='arrow-right' color='#161616' size='SM' />
                  <Link
                    to='/pra-voce/investimentos/'
                    className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline mr-2'
                  >
                    {headerJson.breadcrumb[0]}
                  </Link>
                  <OrangeIcon icon='arrow-right' color='#161616' size='SM' />
                  <p className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline ml-2 mb-0'>{headerJson.breadcrumb[1]}</p>
                </div>
              </div>
              <h1 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 mb-3 text-grayscale--500 mt-3 mt-lg-0 fw-600'>
                Poupança: investimento <span className='d-lg-block'>tranquilo para o futuro</span>
              </h1>
              <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--400 mb-4 pb-2'>
                Investir na Poupança é fácil e seguro para quem quer <span className='d-lg-block'>começar uma reserva financeira, sem valor mínimo </span><span className='d-lg-block'>de aplicação e isento de Imposto de Renda.</span>
              </p>
            </div>
          </div>
        </div>
      </Section>

      <section id='outros-investimentos' className='pb-5 pt-lg-4'>
        <div className='container'>
          <h3 className='fs-28 fs-md-42 text-md-center text-grayscale--500 mt-2'>Benefícios da Poupança</h3>
          <div className='d-flex justify-content-center'>
            <div className='row col-12 box-features'>
              <div className='col-12 col-md-3 mt-2'>
                <div className='col-12 border rounded-3 d-flex d-md-block'>
                  <div className='col-2 col-md-12 pt-3 pt-md-4 pt-lg-3 text-md-center px-0'>
                    <Icon color='orange--extra' width='40' height='40' icon='pagamentos/ir' directory='v2' />
                  </div>
                  <div className='col-10 col-md-12'>
                    <p className='fs-14 lh-20 mt-3 text-md-center'>Imposto de Renda<br />
                      <span className='fs-17 fw-400'>Isento</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-3 mt-2'>
                <div className='col-12 border rounded-3 d-flex d-md-block'>
                  <div className='col-2 col-md-12 pt-3 pt-md-4 pt-lg-3 text-md-center px-0'>
                    <Icon color='orange--extra' width='40' height='40' icon='products/investimentos/fundos-2' directory='v2' />
                  </div>
                  <div className='col-10 col-md-12'>
                    <p className='fs-14 lh-20 mt-3 text-md-center'>Investimento Inicial<br />
                      <span className='fs-17 fw-400'>Não há</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-3 mt-2'>
                <div className='col-12 border rounded-3 d-flex d-md-block'>
                  <div className='col-2 col-md-12 pt-3 pt-md-4 pt-lg-3 text-md-center px-0'>
                    <Icon color='orange--extra' width='40' height='40' icon='pagamentos/fast-money' directory='v2' />
                  </div>
                  <div className='col-10 col-md-12'>
                    <p className='fs-14 lh-20 mt-3 text-md-center'>Resgate<br />
                      <span className='fs-17 fw-400'>Diário</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-3 mt-2'>
                <div className='col-12 border rounded-3 d-flex d-md-block'>
                  <div className='col-2 col-md-12 pt-3 pt-md-4 pt-lg-3 text-md-center px-0'>
                    <Icon color='orange--extra' width='40' height='40' icon='action/calendar' directory='v2' />
                  </div>
                  <div className='col-10 col-md-12'>
                    <p className='fs-14 lh-20 mt-3 text-md-center'>Prazo mínimo<br />
                      <span className='fs-17 fw-400'>Não há</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id='renda-fixa' className='pb-5 pt-md-5'>
        <div className='container'>
          <h2 className='fs-28 fs-md-42 text-md-center text-grayscale--500 mb-4 mt-2 font-sora'>Conheça nossos produtos</h2>
          <div className='d-flex justify-content-center'>
            <div className='col-12 col-md-8 col-lg-5 px-0'>
              <p id='grafico' className='fs-16 text-md-center text-grayscale--400'>Veja qual se encaixa melhor no seus planos e  comece já a investir.</p>
            </div>
          </div>
          <Box>
            <div className='row align-items-center'>
              <div className='col-12 col-lg-4'>
                <div className='box-content mt-0 mt-lg-5 mb-4'>
                  <p className='fw-700 mb-1 text-grayscale--500'>Considerações utilizadas nos exemplos:</p>
                  <ul style={{ listStyleType: 'none' }}>
                    <li>Todos os valores demonstrados são brutos. Títulos isentos de IR, como LCI e Poupança, foram considerados com taxas equivalentes como se o investimento fosse tributável (com gross up).</li>
                    <li>A expectativa é de que a <a href='https://blog.inter.co/taxa-selic-hoje/'>taxa Selic</a> oscile durante o período, do contrário, a rentabilidade poderá ser afetada.</li>
                    <li>Considera-se que o Título do Tesouro Direto é o Tesouro SELIC (LFT) e sua rentabilidade é de 100% da taxa SELIC.</li>
                  </ul>
                </div>
                <div className='mt-2 mt-4 mb-5'>
                  <a
                    style={{ paddingTop: 13 }}
                    title='Comece a investir agora'
                    className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mt-4 text-md-center fw-700 w-100 mw-100 d-block d-md-none '
                    href='https://inter-co.onelink.me/Qyu7/ez1pd6k5'
                    target='_blank'
                    rel='noreferrer'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_01',
                        section_name: '',
                        element_action: 'click button',
                        element_name: 'Quero conhecer',
                        redirect_url: 'https://inter-co.onelink.me/Qyu7/ez1pd6k5',
                      })
                    }}
                  > COMECE A INVESTIR AGORA
                  </a>
                  <button
                    title='Comece a investir agora'
                    className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mt-4 text-md-center fw-700 w-100 mw-100 d-none d-md-block '
                    onClick={() => {
                      setIsModalOpen(true)
                      sendDatalayerEvent({
                        section: 'dobra_01',
                        section_name: '',
                        element_action: 'click button',
                        element_name: 'Comece a investir agora',
                      })
                    }}
                  >
                    COMECE A INVESTIR AGORA
                  </button>
                </div>
              </div>
              <div className='col-12 col-lg-8'>
                <FixedIncome />
              </div>
            </div>
          </Box>
        </div>
      </section>

      <section id='outros-investimentos'>
        <div className='container pt-5 py-md-5'>
          <h2 className='fs-28 fs-md-42 text-md-center text-grayscale--500 mt-2'>Veja outros tipos de investimentos</h2>
          <InvestmentsTab
            section='dobra_3'
            elementAction='click button'
            section_name='Veja outros tipos de investimentos'
          />
        </div>
      </section>

      <section id='novidades-investimentos' className='py-md-4'>
        <div className='container'>
          <div className='col-12 mb-4'>
            <h2 className='fs-28 mb-0 fs-lg-42 text-left text-lg-center text-grayscale--500'>Os melhores conteúdos sobre investimentos</h2>
            <p className='text-left mt-1 text-lg-center mb-0'>
              Esteja atualizado com as novidades do blog e invista com segurança aumentando os seus resultados.
            </p>
          </div>
          <BlogArticles
            type='investments'
            section='dobra_4'
            elementAction='click button'
            elementName='Saiba mais'
            sectionName='Confira algumas dicas para investir melhor'
          />
        </div>
      </section>

      <FAQSection id='perguntas-frequentes' className='bg-gray pt-5 pb-4 pb-md-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 mb-3'>
              <h2 className='fs-28 fs-md-42 text-md-center text-grayscale--500'>Perguntas frequentes</h2>
              <p className='fs-16 text-md-center'>
                <span className='d-lg-block'>Ainda tem dúvidas? Veja se podemos te ajudar respondendo algumas</span> perguntas ou entre em contato com a gente.
              </p>
            </div>
            <FAQ search={false} id='investimentos-debentures' styles='summary-content px-0' data={pageContext.structuredData.faq} />
          </div>
        </div>
      </FAQSection>
      <DisclaimerInterInvest
        sendDatalayerEvent={sendDatalayerEvent}
        dataLayer={dataLayer}
        section='dobra_07'
      />
    </Layout>
  )
}

export default Poupanca
